import React, { useState } from "react";
import Modal from "react-modal";
import { ColorRing } from "react-loader-spinner";
import { set, cloneDeep, isEmpty, get } from "lodash";

import Button from "@/components/Button";
import Dropdown from "@/components/Dropdown";
import InputField from "@/components/InputField";
import {
    ENQUIRY_FORM_INITIAL_STATE,
    ENQUIRY_FORM_ERRORMSG_FIELDS,
    ENQUIRY_FORM_REQUIRED_FIELDS,
    ENQUIRY_STATUS
} from "./constants";
import { REGEX } from "@/utils/AppConstants";

Modal.setAppElement('#root');

const AddEnquirePopUp = ({ togglePopup, isOpen, onAddEnquireSubmit, selectedRow, dropdownOptions }) => {
    const [addEnquireData, setAddEnquireData] = useState(!isEmpty(selectedRow) ? selectedRow : ENQUIRY_FORM_INITIAL_STATE);
    const [errorMessages, setErrorMessages] = useState(ENQUIRY_FORM_ERRORMSG_FIELDS);
    const statusOptions = ENQUIRY_STATUS;
    const [loading, setLoading] = useState(false);
    const buttonLabel = !isEmpty(selectedRow) ? "Update" : "Submit";

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedState = cloneDeep(addEnquireData);
        set(updatedState, name, value);
        setAddEnquireData(updatedState);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const action = !isEmpty(selectedRow) ? "edit" : "create";
        const newErrorMessages = {};
        ENQUIRY_FORM_REQUIRED_FIELDS.forEach((field) => {
            if (!get(addEnquireData, field)) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
                if (field === "contactMedium.email" && !get(addEnquireData, field).toLowerCase().match(REGEX.EMAIL)) {
                    newErrorMessages[field] = "Please enter valid email address";
                };

                if (field === "contactMedium.phoneNumber" && !get(addEnquireData, field).toLowerCase().match(REGEX.PHONE)) {
                    newErrorMessages[field] = "Please enter valid phone number";
                };

                if (field === "quantity" && !get(addEnquireData, field).toString().match(REGEX.WHOLE_NUMBER)) {
                    newErrorMessages[field] = "Please enter valid quantity";
                };

                if (field === "length" && !get(addEnquireData, field).toString().match(REGEX.FRACTION)) {
                    newErrorMessages[field] = "Please enter valid length";
                };
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== '')) {
            setLoading(false);
            return;
        }

        let payload = {};
        if (action === "edit") {
            payload = {
                description: addEnquireData?.description,
                status: addEnquireData?.status,
                firstName: addEnquireData?.firstName,
                lastName: addEnquireData?.lastName,
                leadBy: addEnquireData?.leadBy,
                location: addEnquireData?.location,
                quantity: addEnquireData?.quantity,
                transporterName: addEnquireData?.transporterName,
                typeOfTrailer: addEnquireData?.typeOfTrailer,
                length: addEnquireData?.length,
                width: addEnquireData?.width,
                height: addEnquireData?.height,
                axleType: addEnquireData?.axleType,
                contactMedium: {
                    email: addEnquireData?.contactMedium?.email,
                    phoneNumber: addEnquireData?.contactMedium?.phoneNumber
                },
                competitor: addEnquireData?.competitor,
            }
        } else {
            payload = {
                ...addEnquireData,
                typeOfTrailer: addEnquireData?.typeOfTrailer,
                length: addEnquireData?.length,
                width: addEnquireData?.width,
                height: addEnquireData?.height,
                axleType: addEnquireData?.axleType,
            };
        }
        onAddEnquireSubmit(payload, action);
    }

    const handleOptionSelect = (option, id) => {
        const updatedState = cloneDeep(addEnquireData);
        set(updatedState, id, option.name);
        console.log(updatedState);
        setAddEnquireData(updatedState);
        setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            [id]: '',
        }));
    };

    const handleOptionSelect1 = (option, id) => {
        console.log(option)
    };

    const getOptionKey = ({ productId, productName }) => ({
        id: productId,
        name: productName
    });

    const trailerTypeOptions = dropdownOptions.trailerType.map(getOptionKey);
    //const trailerLengthOptions = dropdownOptions.trailerLength.map(getOptionKey);
    const trailerWidthOptions = dropdownOptions.trailerWidth.map(getOptionKey);
    const trailerHeightOptions = dropdownOptions.trailerHeight.map(getOptionKey);
    const trailerAxleTypeOptions = dropdownOptions.trailerAxleType.map(getOptionKey);
    const testOption = [
        { name: 'name 1', label: 'label 1' },
        { name: 'name 2', label: 'label 2' },
        { name: 'name 3', label: 'label 3' },
        { name: 'name 4', label: 'label 4' },
        { name: 'name 5', label: 'label 5' },
        { name: 'name 6', label: 'label 6' },
        { name: 'name 7', label: 'label 7' },
        { name: 'name 8', label: 'label 8' },
        { name: 'name 9', label: 'label 9' },
        { name: 'name 10', label: 'label 10' },
        { name: 'name 11', label: 'label 11' },
        { name: 'name 12', label: 'label 12' },
        { name: 'name 13', label: 'label 13' },
    ];

    const selectedOption = (string, options) => {
        return options.find((option) => {
            return option.name === string
        })
    };

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={togglePopup}
                contentLabel="Example Modal"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                className="bg-white p-5 rounded shadow-lg w-8/12"
            >
                <div className=''>
                    <span className="text-xl font-small pt-3.5">Enquiry</span>
                    <div className="grid grid-cols-3 gap-2 pt-3.5">
                        <div className="">
                            <Dropdown
                                options={testOption ?? []}
                                selectedOption={selectedOption("name 12", testOption)}
                                onOptionSelect={handleOptionSelect1}
                                label="Test"
                                name="test"
                                id="test"
                                required={true}
                            />
                        </div>
                        <div className="">
                            <InputField
                                name="firstName"
                                label="First Name"
                                placeholder="First Name"
                                value={get(addEnquireData, "firstName")}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages.firstName && <p className="text-red-500 text-[12px] mt-1">{errorMessages.firstName}</p>}
                        </div>
                        <div className="">
                            <InputField
                                label="Last Name"
                                name="lastName"
                                placeholder="Last Name"
                                value={get(addEnquireData, "lastName")}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages.lastName && <p className="text-red-500 text-[12px] mt-1">{errorMessages.lastName}</p>}
                        </div>
                        <div className="">
                            <InputField
                                label="Email"
                                name="contactMedium.email"
                                placeholder="Email"
                                value={get(addEnquireData, "contactMedium.email")}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages['contactMedium.email'] && <p className="text-red-500 text-[12px] mt-1">{errorMessages["contactMedium.email"]}</p>}
                        </div>
                        <div className="">
                            <InputField
                                label="Phone Number"
                                name="contactMedium.phoneNumber"
                                placeholder="Phone Number"
                                value={get(addEnquireData, "contactMedium.phoneNumber")}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages['contactMedium.phoneNumber'] && <p className="text-red-500 text-[12px] mt-1">{errorMessages["contactMedium.phoneNumber"]}</p>}
                        </div>
                        <div className="">
                            <InputField
                                label="Transport Name"
                                name="transporterName"
                                placeholder="Transport Name"
                                value={get(addEnquireData, "transporterName")}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages.transporterName && <p className="text-red-500 text-[12px] mt-1">{errorMessages.transporterName}</p>}
                        </div>
                        <div className="">
                            <Dropdown
                                options={trailerTypeOptions ?? []}
                                selectedOption={selectedOption(get(addEnquireData, "typeOfTrailer"), trailerTypeOptions)}
                                onOptionSelect={handleOptionSelect}
                                label="Trailer Type"
                                name="Trailer type"
                                id="typeOfTrailer"
                                required={true}
                            />
                            {errorMessages.typeOfTrailer && <p className="text-red-500 text-[12px] mt-1">{errorMessages.typeOfTrailer}</p>}
                        </div>
                        {/* <div className="">
                            <Dropdown
                                options={trailerLengthOptions ?? []}
                                selectedOption={selectedOption(get(addEnquireData, "length"), trailerLengthOptions)}
                                onOptionSelect={handleOptionSelect}
                                label="Trailer Length"
                                name="Trailer Length"
                                id="length"
                                required={true}
                            />
                            {errorMessages.length && <p className="text-red-500 text-[12px] mt-1">{errorMessages.length}</p>}
                        </div> */}
                        <div className="">
                            <InputField
                                label="Trailer Length (In Feet)"
                                name="length"
                                placeholder="Trailer Length"
                                value={get(addEnquireData, "length")}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages.length && <p className="text-red-500 text-[12px] mt-1">{errorMessages.length}</p>}
                        </div>
                        <div className="">
                            <Dropdown
                                options={trailerWidthOptions ?? []}
                                selectedOption={selectedOption(get(addEnquireData, "width"), trailerWidthOptions)}
                                onOptionSelect={handleOptionSelect}
                                label="Trailer Width"
                                name="Trailer Width"
                                id="width"
                                required={true}
                            />
                            {errorMessages.width && <p className="text-red-500 text-[12px] mt-1">{errorMessages.width}</p>}
                        </div>
                        <div className="">
                            <Dropdown
                                options={trailerHeightOptions ?? []}
                                selectedOption={selectedOption(get(addEnquireData, "height"), trailerHeightOptions)}
                                onOptionSelect={handleOptionSelect}
                                label="Trailer Height"
                                name="Trailer Height"
                                id="height"
                                required={true}
                            />
                            {errorMessages.height && <p className="text-red-500 text-[12px] mt-1">{errorMessages.height}</p>}
                        </div>
                        <div className="">
                            <Dropdown
                                options={trailerAxleTypeOptions ?? []}
                                selectedOption={selectedOption(get(addEnquireData, "axleType"), trailerAxleTypeOptions)}
                                onOptionSelect={handleOptionSelect}
                                label="Axle Type"
                                name="Axle Type"
                                id="axleType"
                                required={true}
                            />
                            {errorMessages.axleType && <p className="text-red-500 text-[12px] mt-1">{errorMessages.axleType}</p>}
                        </div>
                        <div className="">
                            <InputField
                                label="Address"
                                name="location"
                                placeholder="Address"
                                value={get(addEnquireData, "location")}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages.location && <p className="text-red-500 text-[12px] mt-1">{errorMessages.location}</p>}
                        </div>
                        <div className="">
                            <InputField
                                label="Quantity"
                                name="quantity"
                                placeholder="Quantity"
                                value={get(addEnquireData, "quantity")}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages.quantity && <p className="text-red-500 text-[12px] mt-1">{errorMessages.quantity}</p>}
                        </div>
                        <div className="">
                            <InputField
                                label="Competitor"
                                name="competitor"
                                placeholder="Competitor"
                                value={get(addEnquireData, "competitor")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="">
                            <InputField
                                label="Lead by Dealer / Customer "
                                name="leadBy"
                                placeholder="Lead By Dealer / Customer "
                                value={get(addEnquireData, "leadBy")}
                                onChange={handleInputChange}
                            />
                        </div>
                        {!isEmpty(selectedRow) && <div className="">
                            <Dropdown
                                options={statusOptions ?? []}
                                selectedOption={selectedOption(get(addEnquireData, "status"), statusOptions)}
                                onOptionSelect={handleOptionSelect}
                                label="Status"
                                name="status"
                                id="status"
                                height='85px'
                            />
                        </div>}
                        <div className="">
                            <InputField
                                label="Description"
                                name="description"
                                placeholder="Description"
                                value={get(addEnquireData, "description")}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                {!loading &&
                    <div className="mt-4 flex gap-2">
                        <Button text={buttonLabel} textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit()} />
                        <Button text="Cancel" textColor="Black" bgColor="White" onClick={() => togglePopup()} />
                    </div>
                }
                {loading && <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={["#39ACE4","#39ACE4","#39ACE4","#39ACE4","#39ACE4" ]}
                />}
            </Modal>
        </div>
    );
};

export default AddEnquirePopUp;
