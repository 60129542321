
export const APP_URLS = {
    APP_ROOT: "/dashboard",
    LOGIN: "/login",
    RESET_PWD: "/password/reset",
    HOME: "Home",
    ACCT_DEPT: "AccountDepartment/PaymentList",
    ACC_DEPT_ADD_PAY: "AccountDepartment/AddPayment",
    ACC_DEPT_EDIT_PAY: "AccountDepartment/EditPayment",
    ACC_DEPT_VIEW_RECEIPT: "AccountDepartment/ViewWorkOrderReceipt",
    ACC_DEPT_VIEW_RECEIP_LIST: "AccountDepartment/WorkOrderReceiptList",
    ACC_DEPT_SALES_BALANCE_INFO: "AccountDepartment/SalesBalanceInformation",
    DESN_DEPT: "DesignDepartment",
    PROD_DEPT: "ProductionDepartment",
    QLTY_DISPATCH: "QualityDispatch",
    SALES_ENQ_LIST: "SalesDepartment/Enquiry",
    SALES_ENQ_QUO_LIST: "SalesDepartment/EnquireQuotationList",
    SALES_ENQ_QUO: "SalesDepartment/EnquireQuotation",
    SALES_WO_LIST: "SalesDepartment/WorkOrderList",
    SALE_VIEW_WO_LIST: "SalesDepartment/ViewWorkOrderList",
    SALES_WO_PAYMENT_LIST: "SalesDepartment/ViewWorkOrderPaymentList",
    SALES_WO_RECEIPT_LIST: "SalesDepartment/ViewWorkOrderReceiptList",
    SALES_WO_VIEW_RECEIPT: "SalesDepartment/ViewWorkOrderReceipt",
    ADMIN_QUO_TEMP_LIST: "AdminDepartment/QuotationTemplates",
    ADMIN_QUO_TEMP_ADD: "AdminDepartment/QuotationTemplates/Add",
    ADMIN_WO_LIST: "AdminDepartment/WorkOrderList",
    ADMIN_VIEW_WO: "AdminDepartment/ViewWorkOrder",
    EDIT_WO:"EditWorkOrder",
    VIEW_WO:"ViewWorkOrder",
    VIEW_QUO: "ViewQuotation",
    REPORT: "Reports",
    USR_ACCT: "UserAccounts",
    PROFILE: "Profile",
    CATEGORY: "Masters/Category",
    PRODUCT: "Masters/Product",
    SETTGS: "Settings"
};
